import './index.scss'
import play from './img/play.svg'
import SocialButtons from '../social-buttons'
import Menu from '../menu'
import {
  Link
} from 'react-router-dom'

const Header = ({ smDevice, mdDevice }) => {
  return (
    <div className="header" id="header">
      <Menu mdDevice={mdDevice} />
      <div className="container header__container">
        <div className="header__content">
          <h1 className="title title--light">Пристрої тепловізійного бачення для безпілотників</h1>
          <p className="header__text">
            Ми пропонуємо доступні рішення для швидкого переобладнання будь-якого безпілотного літального апарату
            якісною системою тепловізійного бачення
          </p>
          <div className="header__buttons-wrapper">
            <Link to="/product-1" className="button-main">
              Детальніше
            </Link>
            <a href="https://youtu.be/1hfU1C7JLpM?si=4vXRTFt2Ll8atWRL" target="_blank" className="button-main header__media-button">
              Відеоогляд
            </a>
          </div>
          {!smDevice && (
            <SocialButtons />
          )}
        </div>
        <img
          className="header__bg-img"
          alt="camera"
          src={require('./img/camera.png')}
          srcSet={`${require('./img/camera@2x.png')} 2x, ${require('./img/camera@3x.png')} 3x`}
        />
      </div>
    </div>
  )
}

export default Header